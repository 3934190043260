import Section from 'components/Section'
import styles from './TestimonialCarouselV2.module.scss'
import { useMemoizedContentGetter } from 'utils/contentful'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { TitleDivider } from 'components/TitleDivider'
import { CarouselV2 } from 'components/Carousels/CarouselV2'
import { MediaImageStandard, TypographyLongForm } from 'components/Contentful'
import { VideoDialog } from 'components/VideoDialog'
import { StatGrid } from 'components/StatGrid'

const ItemComponentCarousel = ({ item, index, activeIndex }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  return (
    <div className={styles['testimonial-image-container']}>
      <div className={styles['testimonial-image']}>
        <MediaImageStandard
          content={item?.image}
          layout="fill"
          height={isMobile ? 208 : 364}
          width={isMobile ? 208 : 394}
        />
      </div>
      {activeIndex === index && (
        <div className={styles['testimonial-image-title']}>
          <TypographyLongForm
            variant="bodySm"
            fontWeight="bold"
            component="span"
            content={item?.title}
          />
        </div>
      )}
    </div>
  )
}

const ItemComponentMessage = ({ item }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  return (
    <>
      <div className={styles['testimonial-copy']}>
        <TypographyLongForm content={item?.message} variant={isMobile ? 'bodySm' : 'bodyLg'} />
      </div>
      {item?.statGrid && (
        <div className={styles['stat-grid-container']}>
          <StatGrid grid={item?.statGrid?.fields} />
        </div>
      )}
      {item?.videoDialog && (
        <VideoDialog
          content={item?.videoDialog}
          trackOpen={{
            nav_link_section: 'Testimonials',
          }}
        />
      )}
      {item?.disclaimer && (
        <TypographyLongForm
          content={item?.disclaimer}
          variant="bodySm"
          className={styles['disclaimer']}
        />
      )}
    </>
  )
}

function TestimonialCarouselV2({ content }) {
  const { title, eyebrow, gridDebtConsolidationTestimonials, event, backgroundConfigJson } =
    useMemoizedContentGetter(content, [
      'eyebrow',
      'title',
      'gridDebtConsolidationTestimonials',
      'event',
      'backgroundConfigJson',
    ])

  return (
    <TestimonialCarouselV2Module
      title={title}
      eyebrow={eyebrow}
      gridDebtConsolidationTestimonials={gridDebtConsolidationTestimonials?.gridItemsCollection}
      event={event}
      jsonConfig={backgroundConfigJson?.jsonContent}
    />
  )
}

function TestimonialCarouselV2Module({
  title,
  eyebrow,
  gridDebtConsolidationTestimonials,
  event,
  jsonConfig,
}) {
  const { items = [] } = gridDebtConsolidationTestimonials || {}
  const backgroundConfig = jsonConfig?.background ?? 'bgDefault'

  return (
    <Section className={styles['section']} contain={true} variant={backgroundConfig}>
      {(title || eyebrow) && (
        <TitleDivider
          disableTitleTag={true}
          eyebrow={eyebrow}
          eyebrowBlack
          title={title}
          eyebrowClassName={title ? styles['eyebrow'] : styles['eyebrow-only']}
        />
      )}

      <CarouselV2
        event={event}
        gridItems={items}
        ItemComponentCarousel={ItemComponentCarousel}
        ItemComponentMessage={ItemComponentMessage}
        withMessages
      />
    </Section>
  )
}

export { ItemComponentCarousel, ItemComponentMessage, TestimonialCarouselV2 }

export default TestimonialCarouselV2
